export default class FormDataFactory {
  public static Create<T>(model: T): FormData {
    const formData = new FormData();

    for (const key in model) {
      const value = model[key];

      if (Array.isArray(value)) {
        value.forEach(v => this.appendToFormData(formData, key, v));
      } else {
        this.appendToFormData(formData, key, value);
      }
    }
    return formData;
  }
  public static appendToFormData<T>(
    formData: FormData,
    key: string,
    value: T
  ): void {
    if (value instanceof Blob) {
      formData.append(key, value);
    } else if (value instanceof Object) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value === null) {
        return;
      }

      formData.append(key, String(value));
    }
  }
}
