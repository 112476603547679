export default class FileDownloadUtility {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static DownloadFile(data: any, filename: string) {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: data.type })
    );

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
